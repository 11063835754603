


import { Component, Vue, Watch } from 'vue-property-decorator'
import Modal from '@/components/Modal/Modal.vue'
import Header from '@/components/Header/Header.vue'
import Footer from '@/components/Footer/Footer.vue'
import Process from '@/components/Process/Process.vue'
import Alert from '@/components/Alert/Alert.vue'
import StartView from '@/components/StartView/StartView.vue'
import CounterView from '@/components/CounterView/CounterView.vue'
import ReceiptsView from '@/components/ReceiptsView/ReceiptsView.vue'
import CommissionsView from '@/components/CommissionsView/CommissionsView.vue'
import SubmitView from '@/components/SubmitView/SubmitView.vue'
import FailView from '@/components/FailView/FailView.vue'
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import NoMobileOverlay from '@/components/NoMobileOverlay/NoMobileOverlay.vue'
import moment from 'moment'
import Excel from '@/components/SubmitView/Excel'
import { Program } from '@/models/type'

@Component({
  components: {
    Modal,
    Header,
    Footer,
    Process,
    Alert,
    StartView,
    CounterView,
    ReceiptsView,
    CommissionsView,
    SubmitView,
    FailView,
    Tooltip,
    NoMobileOverlay
  }
})
export default class App extends Vue {
  private dataReady = false
  private viewReady = false
  private pageValid = true
  private failViewText = 'Beim Laden der digitalen Tagesabrechnug ist ein Fehler aufgetreten.'
  private maxSteps = 5
  private steps = [StartView, CounterView, ReceiptsView, CommissionsView, SubmitView]
  private nextStepEnabled = false
  private sentSuccessfully = false
  public scrollPosition = 0
  private scrolled = false
  private nextStepWarning = false
  private closeTooltip = false
  private rebounce = 0
  private rebounceBottom = 100000

  private titles = [
    '',
    'Zählerstände / Leerschaltungen',
    'Tageseinnahmen',
    'Provisionen',
    ''
  ]

  get currentStep (): number { return this.$store.getters.currentStep }

  @Watch('currentStep')
  onCurrentStepChanged (): void {
    this.scrollPosition = 0
    this.scrolled = false
    this.$store.dispatch('clearAlerts')
  }

  async mounted (): Promise<void> {
    moment.locale('de')
    if (this.$route.path.includes('washstreets')) {
      Vue.axios.get(this.$route.path).then(async (response) => {
        if (response.status === 200 && response.data != null) {
          const sortedPrograms: Program[] = response.data.programs.sort((a: Program, b: Program) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          const filteredPrograms: Program[] = []
          sortedPrograms.forEach((program) => {
            if (!(program.counterEndOfDay == null && program.counterStartOfDay == null)) {
              filteredPrograms.push(program)
            }
          })
          response.data.programs = filteredPrograms
          if (response.data.programs.length === 0) {
            this.failViewText = 'Sie müssen zuerst ALLE älteren Tagesabrechnungen bearbeiten.'
            this.pageValid = false
          } else if (response.data.programs[0].counterStartOfDay !== undefined) {
            if (response.data.programs[0].counterStartOfDay === null) {
              this.failViewText = 'Sie müssen zuerst ALLE älteren Tagesabrechnungen bearbeiten.'
              this.pageValid = false
            }
          }
          if (response.data.status === 2 || response.data.status === 3 || response.data.status === 4 || response.data.status === 409) {
            this.$store.commit('currentStep', 5)
            this.sentSuccessfully = true
          }

          try {
            const { date, carwash } = response.data
            if (!!date && !!carwash) {
              const kpiResponse = await Vue.axios.get(`single/washclub-kpis?date=${date}&washstreet=${carwash}`)
              const washClubCredit = Number(kpiResponse.data.totalAmount)
              if (response.data.tariffTotals !== undefined) {
                if (response.data.tariffTotals.receipts !== undefined) {
                  response.data.tariffTotals.receipts.washClubCredit = washClubCredit
                } else {
                  response.data.tariffTotals.receipts = { washClubCredit }
                }
              } else {
                response.data.tariffTotals = { receipts: { washClubCredit } }
              }
            }
          } catch (error) {
            console.log('error :', error)
          }
          this.$store.dispatch('importData', response.data)
          this.onDataReady()
        } else {
          this.pageValid = false
          this.onDataReady()
        }
      },
      () => {
        this.pageValid = false
        this.onDataReady()
      })
    } else if (this.$route.path.includes('exports')) {
      Vue.axios.get(this.$route.path.replace('exports', 'washstreets')).then(async (response) => {
        if (response.status === 200 && response.data != null) {
          this.$store.dispatch('importData', response.data)
          let downloadFailed = false

          if (response.data.status < 2 && response.data.status > 4) {
            downloadFailed = true
          }

          if (downloadFailed === false) {
            const data = this.$store.getters.dataRequest
            try {
              const excel = new Excel(data, false)
              excel.createExcel()
              excel.fillContent()
              excel.formatCells()
              if (data.allScans && data.allScans.length > 0) {
                excel.createExcelTransactions()
                excel.fillContentTransactions()
                excel.formatCellsTransactions()
              }
              const status = await excel.provideExcel()
              if (status === 'error') {
                downloadFailed = true
              } else {
                window.close()
              }
            } catch (error) {
              console.log(error)
              downloadFailed = true
            }
          }

          if (downloadFailed) {
            alert('Die Abrechnung konnte nicht heruntergeladen werden. Bitte wenden Sie sich an den Support.')
          }
        }
      })
    } else {
      this.pageValid = false
      // const exampleData = await import('@/exampleResponse_happyHour.json')
      // console.log('exampleData :', exampleData)
      // this.$store.dispatch('importData', exampleData)
      // // this.$store.commit('currentStep', 5)
      // this.pageValid = true
      this.onDataReady()
    }
  }

  onDataReady (): void {
    this.dataReady = true
    setTimeout(() => {
      this.viewReady = true
    }, 1)
  }

  dataCheck (dataComplete: boolean): void {
    this.nextStepEnabled = dataComplete
    if (dataComplete) {
      this.nextStepWarning = false
    }
  }

  nextStep (): void {
    this.viewReady = false
    if (this.$store.getters.dataRequest.open && this.currentStep < this.maxSteps) {
      this.$store.commit('currentStep', this.currentStep + 1)
    } else {
      this.$store.commit('currentStep', this.maxSteps)
    }
    this.nextStepEnabled = false
    this.nextStepWarning = false
    setTimeout(() => {
      this.viewReady = true
    }, 1)
  }

  back (): void {
    this.viewReady = false
    if (this.$store.getters.dataRequest.open && this.currentStep >= 1) {
      this.$store.commit('currentStep', this.currentStep - 1)
    } else {
      this.$store.commit('currentStep', 1)
    }
    this.nextStepEnabled = false
    setTimeout(() => {
      this.viewReady = true
    }, 1)
  }

  onScroll (e: Event, position: { scrollTop: number, scrollLeft: number}): void {
    this.scrollPosition = position.scrollTop
    const target = e.target as HTMLDivElement
    this.rebounceBottom = target.scrollHeight - target.scrollTop
    if (this.scrollPosition < 0) {
      target.scrollTop = 0
    }
    if (this.rebounceBottom <= target.clientHeight) {
      target.scrollTop = this.scrollPosition
    }
    if (this.scrollPosition > 10) {
      this.scrolled = true
    } else if (this.scrollPosition <= 9) {
      this.scrolled = false
    }
  }
}
