


import Tooltip from '@/components/Tooltip/Tooltip.vue'
import { Data, Program, ReceiptKey, Tariff, TariffKey, TariffTotals } from '@/models/type'
import { Component, Vue, Prop } from 'vue-property-decorator'
import NumberInput from '@/components/NumberInput/NumberInput.vue'

@Component({
  components: {
    Tooltip,
    NumberInput
  }
})
export default class ReceiptsView extends Vue {
  @Prop({ default: false }) nextStepWarning!: boolean
  @Prop({ default: 0 }) private scrollPosition!: number

  tariffs: [TariffKey, string, string?][] = [
    ['latest', 'Aktuelle Preise'],
    ['taxi', 'Taxipreis'],
    ['qrCodeA', 'QR Code A'],
    ['qrCodeC', 'QR Code C'],
    ['couponCash', 'EE Barzahlungscoupon'], // verschoben
    ['prepaid', 'Einzelpreis Prepaidwäsche', 'Prepaidwäschen verkauft'],
    ['washClub', 'Wash-Club Karten Verkauf', 'Stück'],
    ['coupon', 'Couponwäsche']
    // ['groupon', 'Grouponwäsche']
  ]

  eeTariffs: [TariffKey, string, string?][] = [
    // ['couponCash', 'EE Barzahlungscoupon Verkauf', 'Stück'],
    ['ee_pp', 'EE Prepaid Verkauf', 'Stück'],
    ['happyHour', 'EE Happy Hour Verkauf', 'Stück'],
    ['speedyPay', 'EE Quick Pay Verkauf', 'Stück'],
    ['specialOffer', 'EE Sonderaktion Verkauf', 'Stück'],
    ['ee_wc', 'EE Wash-Club Verkauf', 'Stück'],
    ['ee_wash_package_2x', 'EE Waschpaket (2x) Verkauf', 'Stück'],
    ['ee_wash_package_3x', 'EE Waschpaket (3x) Verkauf', 'Stück'],
    ['ee_wash_package_4x', 'EE Waschpaket (4x) Verkauf', 'Stück'],
    ['ee_wash_package_6x', 'EE Waschpaket (6x) Verkauf', 'Stück'],
    ['ee_wash_package_12x', 'EE Waschpaket (12x) Verkauf', 'Stück'],
    ['ee_sub_1', 'EE ABO Einsteiger Verkauf', 'Stück'],
    ['ee_sub_2', 'EE ABO Standard Verkauf', 'Stück'],
    ['ee_sub_3', 'EE ABO Premium Verkauf', 'Stück'],
    ['ee_coupon_0', 'EE Couponwäsche (Gratis) Verkauf', 'Stück']
  ]

  eeCoupons: [TariffKey, number, string[], string[]][] = [
    ['ee_coupon_1', 1, ['EE Couponwäsche (-1€) eingelöst', '(App-Guthaben)'], ['EE Couponwäsche (-1€) bezahlt an WS', '(bar, EC, Kreditkarte)']],
    ['ee_coupon_2', 2, ['EE Couponwäsche (-2€) eingelöst', '(App-Guthaben)'], ['EE Couponwäsche (-2€) bezahlt an WS', '(bar, EC, Kreditkarte)']],
    ['ee_coupon_3', 3, ['EE Couponwäsche (-3€) eingelöst', '(App-Guthaben)'], ['EE Couponwäsche (-3€) bezahlt an WS', '(bar, EC, Kreditkarte)']],
    ['ee_coupon_4', 4, ['EE Couponwäsche (-4€) eingelöst', '(App-Guthaben)'], ['EE Couponwäsche (-4€) bezahlt an WS', '(bar, EC, Kreditkarte)']],
    ['ee_coupon_5', 5, ['EE Couponwäsche (-5€) eingelöst', '(App-Guthaben)'], ['EE Couponwäsche (-5€) bezahlt an WS', '(bar, EC, Kreditkarte)']]
  ]

  receiptsSumTariffs: TariffKey[] = [
    'latest',
    'taxi',
    'qrCodeA',
    'qrCodeC',
    'couponCash',
    'ee_coupon_0',
    'ee_coupon_1',
    'ee_coupon_2',
    'ee_coupon_3',
    'ee_coupon_4',
    'ee_coupon_5'
  ]

  eeWashClubPaidInAdvanceTariffs: TariffKey[] = [
    'ee_washClubRevenues',
    'ee_pp',
    'happyHour',
    'speedyPay',
    'specialOffer',
    'ee_wash_package_2x',
    'ee_wash_package_3x',
    'ee_wash_package_4x',
    'ee_wash_package_6x',
    'ee_wash_package_12x',
    'ee_sub_1',
    'ee_sub_2',
    'ee_sub_3',
    'ee_coupon_1',
    'ee_coupon_2',
    'ee_coupon_3',
    'ee_coupon_4',
    'ee_coupon_5'
  ]

  washClubPaidInAdvanceTariffs: TariffKey[] = [
    'washClubPaidInAdvance'
  ]

  hiddenTariffs: TariffKey[] = [
    'washClubPaidInAdvance',
    'ee_washClubRevenues'
  ]

  saleTariffs: TariffKey[] = [
    'prepaid',
    'washClub'
  ]

  receipts: [ReceiptKey, string][] = [
    ['receiptsEC', 'Einnahmen EC-/<br />Kreditkarten'],
    ['receiptsDKV', 'Einnahmen DKV'],
    ['receiptsUTA', 'Einnahmen UTA'],
    ['receiptsLOGPAY', 'Einnahmen LOGPAY']
  ]

  focusReceipts = -1

  created (): void {
    const programs: Program[] = this.$store.state.dataRequest.programs
    programs.forEach((program) => {
      this.calculateOutputs(program)
    })
    this.addMissingTariffs()
    this.calculateTariffTotals()
    this.calculateCashReceipts()
    this.checkData()

    this.receipts.forEach(receipt => {
      if (this.$store.state.dataRequest[receipt[0]] === undefined || typeof this.$store.state.dataRequest[receipt[0]] !== 'number') {
        this.$store.state.dataRequest[receipt[0]] = null
      } else {
        const currentReceipt = this.$store.state.dataRequest[receipt[0]]
        this.$store.state.dataRequest[receipt[0]] = Intl.NumberFormat('de').format(this.$store.state.dataRequest[receipt[0]])
        setTimeout(() => {
          this.$store.state.dataRequest[receipt[0]] = currentReceipt
          this.checkData()
        }, 1)
      }
    })
  }

  calculate (program: Program): void {
    this.calculateOutputs(program)
    this.addMissingTariffs()
    this.calculateTariffTotals()
    this.calculateCashReceipts()
    this.checkData()
  }

  calculateOutputs (program: Program):void {
    Object.keys(program).forEach((key) => {
      const tariffKey = key as TariffKey
      const tariff = program[tariffKey] as Tariff
      if (tariff) {
        if (tariffKey === 'latest' && tariff.price != null && program.totalAmountCashPrograms != null) {
          (tariff).outputPrice = tariff.price * program.totalAmountCashPrograms
        } else if (this.saleTariffs.includes(tariffKey) || (this.eeTariffs.map(t => t[0]).includes(tariffKey) && tariffKey !== 'ee_coupon_0')) {
          if (tariff.price != null && tariff.amount != null) {
            tariff.outputPrice = tariff.price * tariff.amount
          } else {
            tariff.outputPrice = null
          }
        // } else if (key === 'groupon') {
        //   tariff.outputPrice = 0.0
        } else {
          this.tariffs.forEach((t) => {
            if (tariffKey === t[0]) {
              if (tariff.price != null && tariff.amount != null) {
                tariff.outputPrice = tariff.price * tariff.amount
              } else {
                tariff.outputPrice = null
              }
            }
          })
          this.eeTariffs.forEach((t) => {
            if (tariffKey === t[0]) {
              if (tariff.price != null && tariff.amount != null) {
                tariff.outputPrice = tariff.price * tariff.amount
              } else {
                tariff.outputPrice = null
              }
            }
          })
          this.eeCoupons.forEach((t) => {
            if (tariffKey === t[0]) {
              if (tariff.price != null && tariff.amount != null) {
                tariff.outputPrice = tariff.price * tariff.amount
              } else {
                tariff.outputPrice = null
              }
              if (tariff.price != null && tariff.amountApp != null) {
                tariff.outputPriceApp = tariff.price * tariff.amountApp
              } else {
                tariff.outputPriceApp = null
              }
              if (tariff.price != null && tariff.amountCash != null) {
                tariff.outputPriceCash = tariff.price * tariff.amountCash
              } else {
                tariff.outputPriceCash = null
              }
            }
          })
        }
        if (tariff.outputPrice != null && tariff.outputPrice < 0) {
          tariff.outputPrice = -1
        }
      }
    })
  }

  calculateTariffTotals (): void {
    const programs: Program[] = this.$store.state.dataRequest.programs
    const receipts: Partial<Record<TariffKey, number | { total: number, app: number, cash: number }>> = {}
    this.tariffs.forEach((t) => {
      let tariffTotal = 0
      programs.forEach((program) => {
        const tariff = program[t[0]]
        if (tariff?.outputPrice != null && tariff.outputPrice >= 0) {
          tariffTotal += tariff.outputPrice
        }
      })
      receipts[t[0]] = tariffTotal
    })
    this.eeTariffs.forEach((t) => {
      let tariffTotal = 0
      programs.forEach((program) => {
        const tariff = program[t[0]]
        if (tariff?.outputPrice != null && tariff.outputPrice >= 0) {
          tariffTotal += tariff.outputPrice
        }
      })
      receipts[t[0]] = tariffTotal
    })
    this.eeCoupons.forEach((t) => {
      let tariffTotalApp = 0
      let tariffTotalCash = 0
      programs.forEach((program) => {
        const tariff = program[t[0]]
        if (tariff?.outputPriceApp != null && tariff.outputPriceApp >= 0) {
          tariffTotalApp += tariff.outputPriceApp
        }
        if (tariff?.outputPriceCash != null && tariff.outputPriceCash >= 0) {
          tariffTotalCash += tariff.outputPriceCash
        }
      })
      receipts[t[0]] = {
        total: tariffTotalApp + tariffTotalCash,
        app: tariffTotalApp,
        cash: tariffTotalCash
      }
    })
    this.hiddenTariffs.forEach((t) => {
      let tariffTotal = 0
      programs.forEach((program) => {
        const tariff = program[t]
        if (tariff?.outputPrice != null && tariff.outputPrice >= 0) {
          tariffTotal += tariff.outputPrice
        }
      })
      receipts[t] = tariffTotal
    })
    if (this.$store.state.dataRequest.tariffTotals !== undefined) {
      this.$store.state.dataRequest.tariffTotals.receipts = {
        ...receipts,
        washClubCredit: this.$store.state.dataRequest.tariffTotals.receipts.washClubCredit
      }
    } else {
      this.$store.state.dataRequest.tariffTotals = { receipts }
    }
  }

  calculateCashReceipts (): void {
    const tariffTotals: TariffTotals = this.$store.state.dataRequest.tariffTotals
    let receiptsSum = 0
    let eeWashClubPaidInAdvance = 0
    let washClubPaidInAdvance = 0

    // receiptsSum
    Object.keys(tariffTotals.receipts).forEach((key) => {
      const tariffKey = key as TariffKey
      const total = tariffTotals.receipts[tariffKey]
      if (this.receiptsSumTariffs.includes(tariffKey)) {
        if (this.eeCoupons.map(t => t[0]).includes(tariffKey)) {
          receiptsSum += typeof total !== 'number' ? total.cash : 0
          receiptsSum += typeof total !== 'number' ? total.app : 0
        } else {
          receiptsSum += typeof total === 'number' ? total : 0
        }
      }
    })

    this.receipts.forEach((receipt) => {
      if (
        this.$store.state.dataRequest[receipt[0]] != null &&
        typeof this.$store.state.dataRequest[receipt[0]] === 'number'
      ) {
        receiptsSum -= this.$store.state.dataRequest[receipt[0]]
      }
    })

    // eeWashClubPaidInAdvance
    Object.keys(tariffTotals.receipts).forEach((key) => {
      const tariffKey = key as TariffKey
      const total = tariffTotals.receipts[tariffKey]
      if (this.eeWashClubPaidInAdvanceTariffs.includes(tariffKey)) {
        if (this.eeCoupons.map(t => t[0]).includes(tariffKey)) {
          // eeWashClubPaidInAdvance += typeof total !== 'number' ? total.app : 0
          console.log('total !== \'number\' ? total.app : 0 :', typeof total !== 'number' ? total.app : 0)
        } else {
          eeWashClubPaidInAdvance += typeof total === 'number' ? total : 0
          console.log('total === \'number\' ? total : 0 :', typeof total === 'number' ? total : 0)
        }
      }
    })
    console.log('eeWashClubPaidInAdvance :', eeWashClubPaidInAdvance)

    // washClubPaidInAdvanceTariffs
    Object.keys(tariffTotals.receipts).forEach((key) => {
      const tariffKey = key as TariffKey
      const total = tariffTotals.receipts[tariffKey]
      if (this.washClubPaidInAdvanceTariffs.includes(tariffKey)) {
        washClubPaidInAdvance += typeof total === 'number' ? total : 0
      }
    })

    // Object.keys(tariffTotals.receipts).forEach((key) => {
    //   const tariffKey = key as TariffKey
    //   const total = tariffTotals.receipts[tariffKey]
    //   if (tariffKey === 'ee_washClubRevenues' || tariffKey === 'ee_pp') {
    //     eeWashClubPaidInAdvance += typeof total === 'number' ? total : total.total
    //   // }
    //   // if (this.eeTariffs.map(t => t[0]).includes(tariffKey)) {
    //   //   eeWashClubPaidInAdvance += typeof total === 'number' ? total : total.total
    //   } else if (this.eeCoupons.map(t => t[0]).includes(tariffKey)) {
    //     receiptsSum += typeof total !== 'number' ? total.cash : 0
    //     eeWashClubPaidInAdvance += typeof total !== 'number' ? total.app : 0
    //   } else if (this.receiptsSumTariffs.includes(tariffKey)) {
    //     receiptsSum += typeof total === 'number' ? total : 0
    //   }
    // })

    // let receiptsCash = receiptsSum

    // washClubCredit
    if (tariffTotals.receipts.washClubCredit != null && typeof tariffTotals.receipts.washClubCredit === 'number') {
      receiptsSum -= tariffTotals.receipts.washClubCredit
    }
    // receiptsSum -= eeWashClubPaidInAdvance

    // this.$store.state.dataRequest.receiptsCash = receiptsCash
    this.$store.state.dataRequest.receiptsSum = receiptsSum
    this.$store.state.dataRequest.eeWashClubPaidInAdvance = eeWashClubPaidInAdvance
    this.$store.state.dataRequest.washClubPaidInAdvance = washClubPaidInAdvance
  }

  addMissingTariffs (): void {
    const programs: Program[] = this.$store.state.dataRequest.programs
    const filledDataRequest: Data = JSON.parse(JSON.stringify(this.$store.state.dataRequest))
    programs.forEach((program, index) => {
      const washClubPaidInAdvanceOutputPrice = (program.washClub?.amount ?? 0) * (program.latest?.price ?? 0) + (program.coupon?.outputPrice ?? 0)
      filledDataRequest.programs[index].washClubPaidInAdvance = { outputPrice: washClubPaidInAdvanceOutputPrice }
      const eeWashClubRevenuesOutputPrice = (program.ee_wc?.amount ?? 0) * (program.latest?.price ?? 0)
      filledDataRequest.programs[index].ee_washClubRevenues = { outputPrice: eeWashClubRevenuesOutputPrice }
    })
    this.$store.commit('dataRequest', JSON.parse(JSON.stringify(filledDataRequest)))
  }

  onKeydown (event: KeyboardEvent, receipt: string): void {
    setTimeout(() => {
      if (event.code === 'Backspace' && (event.target as HTMLInputElement).value === '') {
        this.$store.state.dataRequest[receipt] = null
      }
      this.checkData()
    }, 1)
  }

  toggleIdleError (error: boolean): void {
    if (error) {
      const alert = {
        type: 'error',
        message: ['Bitte prüfen Sie die eingegebenen Werte für die Anzahl der Leerschaltungen. Bei Problemen wenden Sie sich an den Support.'],
        button: 'Support kontaktieren',
        disableClosing: true,
        src: { step: 3, reason: 'errorIdle' }
      }
      this.$store.dispatch('createAlert', alert)
    } else {
      const src = { step: 3, reason: 'errorIdle' }
      this.$store.dispatch('closeAlert', src)
    }
  }

  toggleReceiptsError (error: boolean): void {
    if (error) {
      const alert = {
        type: 'error',
        message: ['Bitte prüfen Sie die Aufteilung Ihrer Einnahmen. Die Summe der Einnahmen darf nicht höher sein als die Gesamteinnahmen. Bei Problemen wenden Sie sich an den Support.'],
        button: 'Support kontaktieren',
        disableClosing: true,
        src: { step: 3, reason: 'errorReceipts' }
      }
      this.$store.dispatch('createAlert', alert)
    } else {
      const src = { step: 3, reason: 'errorReceipts' }
      this.$store.dispatch('closeAlert', src)
    }
  }

  checkData (): void {
    let dataComplete = true
    let idleError = false
    this.receipts.forEach(receipt => {
      if (
        this.$store.state.dataRequest[receipt[0]] === undefined ||
        typeof this.$store.state.dataRequest[receipt[0]] !== 'number'
      ) {
        dataComplete = false
      }
    })

    this.$store.state.dataRequest.programs.forEach((program: Program) => {
      Object.keys(program).forEach((key) => {
        const tariff = program[key as TariffKey] as Tariff
        if (tariff && tariff.outputPrice != null && tariff.outputPrice < 0) {
          idleError = true
        }
      })
    })
    this.toggleIdleError(idleError)

    const cashError = this.$store.state.dataRequest.receiptsSum < 0
    this.toggleReceiptsError(cashError)

    this.$store.dispatch('toggleError', { step: 'receipts', value: idleError || cashError })
    // this.$emit('dataCheck', valid)
    this.$emit('dataCheck', dataComplete)
  }
}
