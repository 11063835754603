/* eslint-disable */
import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import moment from 'moment'

export default class Excel {
  workbook = {}

  worksheet = {}

  data = {}

  transactions = []

  isPreview = false

  isIncorrect = false

  tables = []

  lastRow = 0

  lastColumn = { number: 0, letter: 'A' }

  constructor (data, isPreview, isIncorrect) {
    this.data = data
    if (data.allScans) {
      this.transactions = data.allScans
    }
    this.isPreview = isPreview
    this.isIncorrect = isIncorrect
  }

  createExcel () {
    this.workbook = new ExcelJS.Workbook()
    this.workbook.calcProperties.fullCalcOnLoad = true
    this.worksheet = this.workbook.addWorksheet('Tagesabrechnung', {
      pageSetup: {
        paperSize: 9,
        fitToPage: 'fitToWidth',
        margins: {
          left: 0.95, right: 0.7,
          top: 0.75, bottom: 0.75,
          header: 0.3, footer: 0.3
        }
      }
    })
    this.tables = []
    this.lastRow = 0
    this.lastColumn = { number: 0, letter: 'A' }
  }

  createExcelTransactions () {
    this.worksheet = this.workbook.addWorksheet('Transaktionen', {
      pageSetup: {
        paperSize: 9,
        fitToPage: 'fitToWidth',
        margins: {
          left: 0.95, right: 0.7,
          top: 0.75, bottom: 0.75,
          header: 0.3, footer: 0.3
        }
      }
    })
    this.tables = []
    this.lastRow = 0
    this.lastColumn = { number: 0, letter: 'A' }
  }

  fillContent () {
    const titleRow = []
    titleRow[1] = 'Waschstraßen-Tagesabrechnung'
    titleRow[5] = 'Datum:'
    titleRow[6] = new Date(this.data.date)
    this.worksheet.addRow(titleRow)
    this.worksheet.addRow([])
    const subtitleRow = []
    if (this.isIncorrect) {
      subtitleRow[1] = 'Fehlerhaft'
      if (this.isPreview) {
        subtitleRow[2] = 'Vorschau'
      }
    } else if (this.isPreview) {
      subtitleRow[1] = 'Vorschau'
    }
    subtitleRow[5] = 'WS-NR.:'
    subtitleRow[6] = this.data.carwash
    subtitleRow[8] = 'Partner:'
    subtitleRow[9] = this.data.partnerName
    this.worksheet.addRow(subtitleRow)
    this.lastRow = this.worksheet.rowCount

    const counters = this.getCounters()
    this.worksheet.addTable({
      name: 'Zählerstände',
      ref: `A${this.worksheet.rowCount + 2}`,
      headerRow: true,
      style: {
        theme: 'TableStyleLight15',
        showRowStripes: true
      },
      columns: counters.columns,
      rows: counters.rows
    })
    const countersTable = this.worksheet.getTable('Zählerstände')
    this.addTable(countersTable.table, 'decimal')

    const receipts = this.getReceipts()
    this.worksheet.addTable({
      name: 'Tageseinnahmen',
      ref: `A${this.lastRow + 2}`,
      headerRow: true,
      style: {
        theme: 'TableStyleLight15',
        showRowStripes: true
      },
      columns: receipts.columns,
      rows: receipts.rows
    })
    const receiptsTable = this.worksheet.getTable('Tageseinnahmen')
    this.addTable(receiptsTable.table, 'currency')

    const commissions = this.getCommissions()
    this.worksheet.addTable({
      name: 'Provisionen',
      ref: `A${this.lastRow + 2}`,
      headerRow: true,
      style: {
        theme: 'TableStyleLight15',
        showRowStripes: true
      },
      columns: commissions.columns,
      rows: commissions.rows
    })
    const commissionsTable = this.worksheet.getTable('Provisionen')
    this.addTable(commissionsTable.table, 'currency')
  }

  fillContentTransactions () {
    const titleRow = []
    titleRow[1] = 'Einzelnachweise der Transaktionen'
    this.worksheet.addRow(titleRow)

    this.lastRow = this.worksheet.rowCount

    const transactions = this.getTransactions()
    this.worksheet.addTable({
      name: 'Transaktionen',
      ref: `A${this.worksheet.rowCount + 2}`,
      headerRow: true,
      style: {
        theme: 'TableStyleLight15',
        showRowStripes: true
      },
      columns: transactions.columns,
      rows: transactions.rows
    })
    const transactionsTable = this.worksheet.getTable('Transaktionen')
    this.addTable(transactionsTable.table, 'transactions')
  }

  addTable (table, type) {
    const tableSize = table.tableRef.split(':')
    const start = {
      row: Number(tableSize[0].match(/[0-9]+/g)[0]),
      column: {
        letter: tableSize[0].match(/[A-Z]+/g)[0],
        number: table.columns.length
      }
    }
    const end = {
      row: Number(tableSize[1].match(/[0-9]+/g)[0]),
      column: {
        letter: tableSize[1].match(/[A-Z]+/g)[0],
        number: table.columns.length
      }
    }
    const newTable = { start, end, type }
    this.tables.push(newTable)
    if (end.column.number > this.lastColumn.number) {
      this.lastColumn = { ...end.column }
    }
    this.lastRow = end.row
  }

  getCounters () {
    const columns = []
    const tariffs = [
      ['qrBillingCustomer', 'QR Rechnungskunde'],
      ['taxi', 'Taxiwäschen'],
      ['qrCodeA', 'QR Code A (Rabattpreise)'],
      ['qrCodeC', 'QR Code C (Aktionspreise)'],
      ['couponCash', 'EE Barzahlungscoupon eingelöst'],
      ['prepaid', 'Prepaidwäschen eingelöst'],
      ['washClub', 'Wash-Club Wäschen eingelöst'],
      ['coupon', 'Couponwäschen eingelöst'],
      ['ee_pp', 'EE Prepaid eingelöst'],
      ['happyHour', 'EE Happy Hour eingelöst'],
      ['specialOffer', 'EE Sonderaktion EE eingelöst'],
      ['speedyPay', 'EE Quick Pay eingelöst'],
      ['ee_wc', 'EE Wash-Club eingelöst'],
      ['ee_wash_package_2x', 'EE Waschpaket (2x) eingelöst'],
      ['ee_wash_package_3x', 'EE Waschpaket (3x) eingelöst'],
      ['ee_wash_package_4x', 'EE Waschpaket (4x) eingelöst'],
      ['ee_wash_package_6x', 'EE Waschpaket (6x) eingelöst'],
      ['ee_wash_package_12x', 'EE Waschpaket (12x) eingelöst'],
      ['ee_sub_1', 'EE ABO Einsteiger eingelöst'],
      ['ee_sub_2', 'EE ABO Standard eingelöst'],
      ['ee_sub_3', 'EE ABO Premium eingelöst'],
      ['ee_coupon_0', 'EE Couponwäsche (Gratis) eingelöst'],
    ]
    const coupons = [
      ['ee_coupon_1', 'EE Couponwäsche (-1€) eingelöst (App-Guthaben)', 'EE Couponwäsche (-1€) bezahlt an WS (bar, EC, Kreditkarte)'],
      ['ee_coupon_2', 'EE Couponwäsche (-2€) eingelöst (App-Guthaben)', 'EE Couponwäsche (-2€) bezahlt an WS (bar, EC, Kreditkarte)'],
      ['ee_coupon_3', 'EE Couponwäsche (-3€) eingelöst (App-Guthaben)', 'EE Couponwäsche (-3€) bezahlt an WS (bar, EC, Kreditkarte)'],
      ['ee_coupon_4', 'EE Couponwäsche (-4€) eingelöst (App-Guthaben)', 'EE Couponwäsche (-4€) bezahlt an WS (bar, EC, Kreditkarte)'],
      ['ee_coupon_5', 'EE Couponwäsche (-5€) eingelöst (App-Guthaben)', 'EE Couponwäsche (-5€) bezahlt an WS (bar, EC, Kreditkarte)']
    ]
    const rows = [
      ['Ende - Tagesende'],
      ['Start - Tagesbeginn'],
      ['Differenz'],
      ['Leerschaltung'],
    ]
    tariffs.forEach((tariff) => {
      rows.push([tariff[1]])
    })
    coupons.forEach((tariff) => {
      rows.push([tariff[1]])
    })
    coupons.forEach((tariff) => {
      rows.push([tariff[2]])
    })
    rows.push(['Summe Barwäsche'])

    columns.push({ name: 'Zählerstände' })
    this.data.programs.forEach((program) => {
      columns.push({ name: program.name })
      rows[0].push(program.counterEndOfDay)
      rows[1].push(program.counterStartOfDay)
      rows[2].push(program.difference)
      rows[3].push(program.amountIdlePrograms)

      let rowIndex = 4
      tariffs.forEach((tariff) => {
        rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].amount != null ? program[tariff[0]].amount : null)
        rowIndex += 1
      })
      coupons.forEach((tariff) => {
        rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].amountApp != null ? program[tariff[0]].amountApp : null)
        rowIndex += 1
      })
      coupons.forEach((tariff) => {
        rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].amountCash != null ? program[tariff[0]].amountCash : null)
        rowIndex += 1
      })
      rows[rowIndex].push(program.totalAmountCashPrograms)
    })
    rows.push([])

    const counterTotals = []
    counterTotals[columns.length - 5] = 'Tageszähler'
    counterTotals[columns.length - 4] = this.data.counterDaily
    counterTotals[columns.length - 2] = 'Gesamtzähler'
    counterTotals[columns.length - 1] = this.data.counterTotal
    rows.push(counterTotals)

    return { rows, columns }
  }

  getReceipts () {
    const tariffs = [
      ['ee_washClubRevenues', null, null, 'Wash Club Erlöse EE'],
      ['washClubPaidInAdvance', null, null, 'Wash Club Erlöse (vorab bezahlt)'],
      ['latest', 'Aktuelle Preise', null, 'Ergebnis'],
      ['taxi', 'Taxipreis', null, 'Ergebnis'],
      ['qrCodeA', 'QR Code A', null, 'Ergebnis'],
      ['qrCodeC', 'QR Code C', null, 'Ergebnis'],
      ['prepaid', 'Einzelpreis Prepaidwäsche', 'Prepaidwäschen verkauft', 'Ergebnis'],
      ['washClub', 'Wash-Club Karten Verkauf', 'Stück', 'Ergebnis'],
      ['coupon', 'Couponwäsche', null, 'Ergebnis'],
      ['ee_pp', 'EE Prepaid Verkauf', 'Stück', 'Ergebnis'],
      ['happyHour', 'EE Happy Hour Verkauf', 'Stück', 'Ergebnis'],
      ['speedyPay', 'EE Quick Pay Verkauf', 'Stück', 'Ergebnis'],
      ['specialOffer', 'EE Sonderaktion Verkauf', 'Stück', 'Ergebnis'],
      ['couponCash', 'EE Barzahlungscoupon Verkauf','Stück', 'Ergebnis' ],
      ['ee_wc', 'EE Wash-Club Verkauf', 'Stück', 'Ergebnis'],
      ['washClubCredit', 'EE WashClub Guthaben eingelöst'],
      ['ee_wash_package_2x', 'EE Waschpaket (2x) Verkauf', 'Stück', 'Ergebnis'],
      ['ee_wash_package_3x', 'EE Waschpaket (3x) Verkauf', 'Stück', 'Ergebnis'],
      ['ee_wash_package_4x', 'EE Waschpaket (4x) Verkauf', 'Stück', 'Ergebnis'],
      ['ee_wash_package_6x', 'EE Waschpaket (6x) Verkauf', 'Stück', 'Ergebnis'],
      ['ee_wash_package_12x', 'EE Waschpaket (12x) Verkauf', 'Stück', 'Ergebnis'],
      ['ee_sub_1', 'EE ABO Einsteiger Verkauf', 'Stück', 'Ergebnis'],
      ['ee_sub_2', 'EE ABO Standard Verkauf', 'Stück', 'Ergebnis'],
      ['ee_sub_3', 'EE ABO Premium Verkauf', 'Stück', 'Ergebnis'],
      ['ee_coupon_0', 'EE Couponwäsche (Gratis) Verkauf', 'Stück', 'Ergebnis'],
    ]
    const coupons = [
      ['ee_coupon_1', 'EE Couponwäsche (-1€) eingelöst (App-Guthaben)', 'EE Couponwäsche (-1€) bezahlt an WS (bar, EC, Kreditkarte)'],
      ['ee_coupon_2', 'EE Couponwäsche (-2€) eingelöst (App-Guthaben)', 'EE Couponwäsche (-2€) bezahlt an WS (bar, EC, Kreditkarte)'],
      ['ee_coupon_3', 'EE Couponwäsche (-3€) eingelöst (App-Guthaben)', 'EE Couponwäsche (-3€) bezahlt an WS (bar, EC, Kreditkarte)'],
      ['ee_coupon_4', 'EE Couponwäsche (-4€) eingelöst (App-Guthaben)', 'EE Couponwäsche (-4€) bezahlt an WS (bar, EC, Kreditkarte)'],
      ['ee_coupon_5', 'EE Couponwäsche (-5€) eingelöst (App-Guthaben)', 'EE Couponwäsche (-5€) bezahlt an WS (bar, EC, Kreditkarte)']
    ]

    const columns = []
    const rows = []
    const totalsRows = []

    tariffs.forEach((tariff) => {
      tariff.forEach((tariffRow, index) => {
        if (index > 0 && tariffRow != null) {
          rows.push([tariffRow])
          if (tariffRow === 'Ergebnis' || tariffRow === 'Wash Club Erlöse EE' || tariffRow === 'Wash Club Erlöse (vorab bezahlt)' || tariffRow === 'EE WashClub Guthaben eingelöst') {
            totalsRows.push([tariff[0], rows.length - 1])
          }
        }
      })
    })
    coupons.forEach((tariff) => {
      rows.push([tariff[1]])
      rows.push(['Stück'])
      rows.push(['Ergebnis'])
      totalsRows.push([`${tariff[0]}_app`, rows.length - 1])
    })
    coupons.forEach((tariff) => {
      rows.push([tariff[2]])
      rows.push(['Stück'])
      rows.push(['Ergebnis'])
      totalsRows.push([`${tariff[0]}_cash`, rows.length - 1])
    })

    columns.push({ name: 'Tageseinnahmen' })
    this.data.programs.forEach((program) => {
      columns.push({ name: program.name })
      let rowIndex = 0
      tariffs.forEach((tariff) => {
        if (tariff[1] != null && tariff[1] != null) {
          rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].price != null ? program[tariff[0]].price : null)
          rowIndex += 1
        }
        if (tariff[2] != null && tariff[2] != null) {
          if (tariff[0] === 'ee_coupon_0') {
            rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].amount != null && program[tariff[0]].price != null ? program[tariff[0]].amount : null)
          } else {
            rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].amount != null && program[tariff[0]].price != null ? program[tariff[0]].amount : null)
          }
          rowIndex += 1
        }
        if (tariff[3] != null && tariff[3] != null) {
          rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].outputPrice != null ? program[tariff[0]].outputPrice : null)
          rowIndex += 1
        }
      })
      coupons.forEach((tariff) => {
        rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].price != null ? program[tariff[0]].price : null)
        rowIndex += 1
        rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].amountApp != null && program[tariff[0]].price ? program[tariff[0]].amountApp : null)
        rowIndex += 1
        rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].outputPriceApp != null ? program[tariff[0]].outputPriceApp : null)
        rowIndex += 1
      })
      coupons.forEach((tariff) => {
        rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].price != null ? program[tariff[0]].price : null)
        rowIndex += 1
        rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].amountCash != null && program[tariff[0]].price ? program[tariff[0]].amountCash : null)
        rowIndex += 1
        rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].outputPriceCash != null ? program[tariff[0]].outputPriceCash : null)
        rowIndex += 1
      })
    })

    columns.push({ name: 'Gesamt' })
    totalsRows.forEach((tariff) => {
      const total = this.data.tariffTotals.receipts[tariff[0]]
      if (total != null) {
        rows[tariff[1]].push(total)
      } else {
        const totalApp = this.data.tariffTotals.receipts[tariff[0].replace('_app', '')]
        const totalCash = this.data.tariffTotals.receipts[tariff[0].replace('_cash', '')]
        if (totalApp?.app != null) {
          rows[tariff[1]].push(totalApp.app)
        } else if (totalCash?.cash != null) {
          rows[tariff[1]].push(totalCash.cash)
        }
      }
    })

    rows.push([])

    const receipts = [
      ['receiptsSum', 'Bareinnahmen'],
      ['receiptsEC', 'Einnahmen EC/KK'],
      ['receiptsDKV', 'Einnahmen DKV'],
      ['receiptsUTA', 'Einnahmen UTA'],
      ['receiptsLOGPAY', 'Einnahmen LOGPAY'],
      ['eeWashClubPaidInAdvance', 'EE - Wash Club vorab bezahlt'],
      ['washClubPaidInAdvance', 'Wash Club vorab bezahlt'],
    ]
    receipts.forEach((receipts, index) => {
      const total = []
      if (receipts[2]) {
        total[columns.length - 4] = receipts[2]
      }
      total[columns.length - 2] = receipts[1]
      total[columns.length - 1] = this.data[receipts[0]]
      rows.push(total)
    });

    return { rows, columns }
  }

  getCommissions () {
    const tariffs = [
      ['latest', 'Aktuelle Provisionen', 'Ergebnis'],
      ['qrBillingCustomer', 'QR Rechnungskunde', 'Ergebnis'],
      ['taxi', 'Taxipreis', 'Ergebnis'],
      ['qrCodeA', 'QR Code A', 'Ergebnis'],
      ['qrCodeC', 'QR Code C', 'Ergebnis'],
      ['prepaid', 'Prepaid eingelöst', 'Ergebnis'],
      ['washClub', 'Wasch Club eingelöst', 'Ergebnis'],
      ['coupon', 'Coupon eingelöst', 'Ergebnis'],
      ['ee_pp', 'EE Prepaid', 'Ergebnis'],
      ['happyHour', 'EE Happy Hour', 'Ergebnis'],
      ['speedyPay', 'EE Quick Pay', 'Ergebnis'],
      ['specialOffer', 'EE Sonderaktion', 'Ergebnis'],
      ['couponCash', 'EE Barzahlungscoupon', 'Ergebnis'],
      ['ee_wc', 'EE Wash-Club', 'Ergebnis'],
      ['ee_wash_package_2x', 'EE Waschpaket (2x)', 'Ergebnis'],
      ['ee_wash_package_3x', 'EE Waschpaket (3x)', 'Ergebnis'],
      ['ee_wash_package_4x', 'EE Waschpaket (4x)', 'Ergebnis'],
      ['ee_wash_package_6x', 'EE Waschpaket (6x)', 'Ergebnis'],
      ['ee_wash_package_12x', 'EE Waschpaket (12x)', 'Ergebnis'],
      ['ee_sub_1', 'EE ABO Einsteiger', 'Ergebnis'],
      ['ee_sub_2', 'EE ABO Standard', 'Ergebnis'],
      ['ee_sub_3', 'EE ABO Premium', 'Ergebnis'],
      ['ee_coupon_0', 'EE Couponwäsche (Gratis) eingelöst', 'Ergebnis'],
      ['ee_coupon_1', 'EE Couponwäsche (-1€) eingelöst', 'Ergebnis'],
      ['ee_coupon_2', 'EE Couponwäsche (-2€) eingelöst', 'Ergebnis'],
      ['ee_coupon_3', 'EE Couponwäsche (-3€) eingelöst', 'Ergebnis'],
      ['ee_coupon_4', 'EE Couponwäsche (-4€) eingelöst', 'Ergebnis'],
      ['ee_coupon_5', 'EE Couponwäsche (-5€) eingelöst', 'Ergebnis'],
    ]

    const columns = []
    const rows = []
    const totalsRows = []

    tariffs.forEach((tariff) => {
      tariff.forEach((tariffRow, index) => {
        if (index > 0 && tariffRow != null) {
          rows.push([tariffRow])
          if (tariffRow === 'Ergebnis') {
            totalsRows.push([tariff[0], rows.length - 1])
          }
        }
      })
    })

    columns.push({ name: 'Provisionen' })
    this.data.programs.forEach((program) => {
      columns.push({ name: program.name })
      let rowIndex = 0
      tariffs.forEach((tariff) => {
        if (tariff[1] != null && tariff[1] != null) {
          rows[rowIndex].push(
            program[tariff[0]] && program[tariff[0]].commissions != null && program[tariff[0]].outputCommissions != null
            ? (program[tariff[0]].commissionsRelative === true
              ? String(program[tariff[0]].commissions)
              : program[tariff[0]].commissions)
            : null
          )
          rowIndex += 1
        }
        if (tariff[2] != null && tariff[2] != null) {
          rows[rowIndex].push(program[tariff[0]] && program[tariff[0]].outputCommissions != null ? program[tariff[0]].outputCommissions : null)
          rowIndex += 1
        }
      })
    })
    
    columns.push({ name: 'Gesamt' })
    totalsRows.forEach((tariff) => {
      const total = this.data.tariffTotals.commissions[tariff[0]]
      if (total != null) {
        rows[tariff[1]].push(total)
      }
    })

    rows.push([])

    const commissionsNet = []
    commissionsNet[columns.length - 2] = 'Prov. Netto'
    commissionsNet[columns.length - 1] = this.data.commissionsNet
    rows.push(commissionsNet)

    const vat = []
    vat[columns.length - 2] = '+ Mwt. 19%'
    vat[columns.length - 1] = this.data.vat
    rows.push(vat)

    const commissionsGross = []
    commissionsGross[columns.length - 2] = 'Prov. Brutto'
    commissionsGross[columns.length - 1] = this.data.commissionsGross
    rows.push(commissionsGross)

    const deposits = []
    deposits[columns.length - 6] = 'Einzahlung ohne Provisionsabzug'
    deposits[columns.length - 5] = this.data.depositGross
    deposits[columns.length - 2] = 'Einzahlung mit Provisionsabzug'
    deposits[columns.length - 1] = this.data.depositNet
    rows.push(deposits)

    const depositsSAP = []
    depositsSAP[columns.length - 6] = 'Kontrollsumme SAP ohne Provisionsabzug'
    depositsSAP[columns.length - 5] = this.data.depositGrossSAP
    depositsSAP[columns.length - 2] = 'Kontrollsumme SAP mit Provisionsabzug'
    depositsSAP[columns.length - 1] = this.data.depositNetSAP
    rows.push(depositsSAP)

    return { rows, columns }
  }

  getTransactions () {
    const columns = []
    const rows = []
    const columnTitles = [
      'Datum',
      'Nettoprovision',
      'Programm-Nummer',
      'Programm-Name',
    ]
    columnTitles.forEach((title) => columns.push({ name: title }))
    this.transactions.forEach((data) => {
      rows.push([moment(data.scan_date).format('DD.MM.YYYY'), data.provison_netto, data.washprogram_number, data.washprogram_name])
    })
    return { rows, columns }
  }

  formatCells () {
    this.worksheet.mergeCells('A1:D1')
    this.worksheet.mergeCells('F1:G1')

    this.worksheet._columns.forEach((column, index) => {
      column.alignment = { vertical: 'middle', horizontal: 'left' }
      if (index === 0) {
        column.width = 25
      } else if (index  === this.lastColumn.number - 1) {
        column.width = 15
      } else {
        column.width = 10
      }
    })

    this.tables.forEach((table) => {
      const header = this.worksheet._rows[table.start.row - 1]
      header.height = 25
      header._cells.forEach((cell) => {
        cell.font = { size: 13 }
      })
      for (let index = table.start.row - 1; index < table.end.row; index++) {
        const row = this.worksheet._rows[index]
        if (row._cells.length && row._cells.length > 0) {
          row._cells.forEach((cell, column) => {
            if (column > 0) {
              cell.alignment = { vertical: 'middle', horizontal: 'right' }
            }
          });
          if (table.type === 'decimal') {
            row.numFmt = '#,###0'
          } else if (table.type === 'currency') {
            const { value } = row._cells[0]
            if (!(value === 'Stück' || value === 'Prepaidwäsche verkauft')) {
              row.numFmt = '#,##0.00" €";[Red]\-#,##0.00" €"'
            }
          // } else if (table.type === 'transactions') {
          }
          row._cells.forEach((cell, column) => {
            if (column > 0) {
              if (typeof cell.value === 'string') {
                if (Number(cell.value)) {
                  cell.value = Number(cell.value / 100)
                  cell.numFmt = '0.00%'
                  // cell.numFmt = '0%'
                }
              }
            }
          });
        }
      }
    })

    const boldCells = [
      'Waschstraßen-Tagesabrechnung',
      'Vorschau',
      'Fehlerhaft',
      'Tageszähler',
      'Gesamtzähler',
      'Einnahmen',
      'Bareinnahmen',
      'Einnahmen EC/KK',
      'Einnahmen DKV',
      'Einnahmen UTA',
      'Einnahmen LOGPAY',
      'EE - Wash Club vorab bezahlt',
      'Wash Club vorab bezahlt',
      'Prov. Netto',
      '+ Mwt. 19%',
      'Prov. Brutto',
      'Einzahlung ohne Provisionsabzug',
      'Einzahlung mit Provisionsabzug',
      'Kontrollsumme SAP ohne Provisionsabzug',
      'Kontrollsumme SAP mit Provisionsabzug',
    ]

    boldCells.forEach((cellName) => {
      this.worksheet.addConditionalFormatting({
        ref: `A1:${this.lastColumn.letter}${this.lastRow}`,
        rules: [
          {
            type: 'containsText',
            operator: 'containsText',
            text: [cellName],
            style: { font: { bold: true } }
          }
        ]
      })
    })

    this.worksheet.getCell('A1').font = { size: 16 }
    this.worksheet.getCell('A3').font = { size: 26, color: { argb: 'FFDD3636' } }
    this.worksheet.getCell('B3').font = { size: 26, color: { argb: 'FFDD3636' } }
    this.worksheet.getCell('F1').font = { bold: true }
  }

  formatCellsTransactions () {
    this.worksheet.mergeCells('A1:D1')

    this.worksheet._columns.forEach((column, index) => {
      column.alignment = { vertical: 'middle', horizontal: 'left' }
      if (index  === this.lastColumn.number - 1) {
        column.width = 40
      } else {
        column.width = 20
      }
    })

    this.tables.forEach((table) => {
      const header = this.worksheet._rows[table.start.row - 1]
      header.height = 25
      header._cells.forEach((cell) => {
        cell.font = { size: 13 }
      })
      for (let index = table.start.row - 1; index < table.end.row; index++) {
        const row = this.worksheet._rows[index]
        if (row._cells.length && row._cells.length > 0) {
          row._cells.forEach((cell, column) => {
            if (column === 1 || column === 2) {
              cell.alignment = { vertical: 'middle', horizontal: 'right' }
            }
            if (column === 0) {
              cell.numFmt = 'dd.mm.yyyy'
            } else if (column === 1) {
              // cell.alignment = { vertical: 'middle', horizontal: 'right' }
              cell.numFmt = '#,##0.00" €";[Red]\-#,##0.00" €"'
            }
          });
        }
      }
    })

    const boldCells = [
      'Einzelnachweise der Transaktionen'
    ]

    boldCells.forEach((cellName) => {
      this.worksheet.addConditionalFormatting({
        ref: `A1:${this.lastColumn.letter}${this.lastRow}`,
        rules: [
          {
            type: 'containsText',
            operator: 'containsText',
            text: [cellName],
            style: { font: { bold: true } }
          }
        ]
      })
    })

    this.worksheet.getCell('A1').font = { size: 16 }
  }

  async provideExcel () {
    let status
    await this.workbook.xlsx.writeBuffer().then((buffer) => {
      const excelTitleStart = 'IMO-Tagesabrechnung_'
      const excelTitleEnd = `${this.data.date}.xlsx`
      const excelTitleConditional = this.isIncorrect
        ? this.isPreview
          ? 'Fehlerhaft_Vorschau_'
          : 'Fehlerhaft_'
        : this.isPreview
          ? 'Vorschau_'
          : ''
      const excelTitle = excelTitleStart + excelTitleConditional + excelTitleEnd
      FileSaver.saveAs(new Blob([buffer]), excelTitle)
      status = 'success'
    }).catch((error) => {
      console.error(error)
      status = 'error'
    })
    return status
  }
}
